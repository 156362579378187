@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import "//fonts.googleapis.com/icon?family=Material+Icons";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.material-icons, .index .block-information dl:after {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: middle;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga"; }

.hidden {
  display: none !important; }

.smt {
  display: none; }

@media only screen and (max-width: 764px) {
  .smt {
    display: block; } }
.btn {
  display: inline-block;
  padding: 5px 20px;
  margin-bottom: 0;
  font-size: 1.4rem;
  line-height: 1.6;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 6px; }
  .btn.btn-sm {
    font-size: 1.2rem;
    padding: 10px 30px; }
  .btn.btn-lg {
    font-size: 1.8rem;
    padding: 10px 40px; }
  .btn.btn-xlg {
    font-size: 1.8rem;
    padding: 20px 100px;
    border-radius: 40px; }
  .btn-primary {
    color: #002359;
    font-weight: bold;
    border: 2px solid #002359;
    text-decoration: none;
    border-radius: 40px; }
  .btn-secondary {
    color: #FFF;
    border: #AD0000;
    background: #AD0000;
    font-weight: bold;
    text-decoration: none;
    border-radius: 40px; }
  .btn [class^="icon-"] {
    margin-right: 5px; }
  .btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
    opacity: 1; }
  .btn:hover, .btn:focus, .btn.focus {
    text-decoration: none;
    opacity: 0.8; }
  .btn:active, .btn.active {
    outline: 0;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn.disabled, .btn[disabled] {
    pointer-events: none;
    opacity: 0.5;
    box-shadow: none; }

@media only screen and (max-width: 764px) {
  .btn {
    white-space: normal; } }
html,
body {
  width: 100%;
  height: 100%; }

html {
  visibility: hidden; }

.wf-active {
  visibility: visible; }

html {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Lucida Grande", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 62.5%;
  text-size-adjust: 100%;
  color: #111;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  line-height: 1.6;
  font-size: 1.4rem;
  background: #F3F2F2; }

* {
  box-sizing: border-box; }

img {
  max-width: 100%;
  vertical-align: top; }

a {
  color: #002359;
  text-decoration: none; }
  a:hover {
    color: rgba(0, 35, 89, 0.7); }

.small {
  font-size: 1.2rem; }

.xsmall {
  font-size: 1rem; }

.noscroll {
  position: fixed;
  overflow-x: scroll;
  overflow-y: scroll; }

.gwrapper {
  min-width: 1200px; }

.gfooter {
  padding: 0 0 60px 0;
  width: 100%;
  text-align: left;
  color: #FFF;
  background: #AD0000; }
  .gfooter__content {
    padding: 30px 0 30px 0;
    background: #393939; }
    .gfooter__content-inner {
      zoom: 1;
      width: 990px;
      margin: 0 auto; }
      .gfooter__content-inner:after {
        content: "";
        display: block;
        clear: both;
        visibility: hidden;
        height: 0.1px;
        font-size: 0.1em;
        line-height: 0; }
  .gfooter .content__address {
    width: 480px;
    float: left;
    text-align: left; }
    .gfooter .content__address-siteid {
      width: 180px;
      vertical-align: top;
      display: inline-block;
      padding-right: 10px; }
    .gfooter .content__address-text {
      width: 280px;
      display: inline-block;
      font-size: 1.2rem; }
  .gfooter .content__share {
    width: 200px;
    float: left;
    height: 80px;
    padding-left: 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.2); }
  .gfooter .content__contact {
    width: 300px;
    float: right;
    text-align: center; }
    .gfooter .content__contact-btn {
      margin-bottom: 5px; }
    .gfooter .content__contact-btn i {
      margin-right: 4px; }
    .gfooter .content__contact-btn .btn-footer {
      width: 290px;
      padding: 10px 0;
      border-radius: 30px;
      font-size: 1.6rem;
      background: #AD0000;
      color: #FFF;
      font-weight: bold; }
    .gfooter .content__contact-link a {
      font-size: 1.2rem;
      color: #FFF; }
  .gfooter address {
    padding-top: 30px;
    text-align: center; }

.gheader {
  zoom: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  z-index: 10; }
  .gheader:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0.1px;
    font-size: 0.1em;
    line-height: 0; }
  .gheader-nav {
    display: inline-block;
    float: right;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 30px;
    padding-right: 20px;
    font-weight: bold; }
    .gheader-nav li {
      display: inline-block;
      vertical-align: middle;
      padding: 15px 15px;
      position: relative; }
    .gheader-nav .active:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #FFF; }
    .gheader-nav .active.contact:after {
      display: none; }
    .gheader-nav li a {
      color: #FFF; }
    .gheader-nav i {
      margin-right: 4px; }
    .gheader-nav .btn-contact {
      width: 200px;
      display: inline-block;
      text-align: center;
      padding: 9px 0;
      height: 40px;
      border-radius: 30px;
      background: #AD0000;
      color: #FFF;
      font-weight: bold; }

.mainvisual {
  width: 100%;
  background: #666;
  position: relative;
  margin-bottom: 40px; }
  .mainvisual__siteid {
    position: absolute;
    width: 120px;
    left: 20px;
    top: 10px; }
  .mainvisual__title {
    padding: 20px 0 30px 0;
    text-align: center;
    color: #FFF; }
    .mainvisual__title h1 {
      font-family: "baskerville-urw", "游明朝体", "Yu Mincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
      font-size: 4.6rem; }
    .mainvisual__title p {
      font-size: 1.6rem; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  content: "";
  display: table; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #ffffff; }

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg"); }
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px \9;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }

.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent; }

.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25; }

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: #ffffff;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }

.slick-prev[dir="rtl"] {
  left: auto;
  right: -25px; }

.slick-prev:before {
  content: "←"; }

.slick-prev:before[dir="rtl"] {
  content: "→"; }

.slick-next {
  right: -25px; }

.slick-next[dir="rtl"] {
  left: -25px;
  right: auto; }

.slick-next:before {
  content: "→"; }

.slick-next:before[dir="rtl"] {
  content: "←"; }

/* Dots */
.slick-slider {
  /*margin-bottom: 30px;*/ }

.slick-dots {
  position: absolute;
  bottom: 60px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  color: #000000;
  opacity: 0.75; }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem; }

.row {
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.row.reverse {
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse; }

.col-xs, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  box-sizing: border-box;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem; }

.col-xs {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  -webkit-flex-basis: 0;
  flex-basis: 0;
  max-width: 100%; }

.col-xs-1 {
  -ms-flex-preferred-size: 8.333%;
  -webkit-flex-basis: 8.333%;
  flex-basis: 8.333%;
  max-width: 8.333%; }

.col-xs-2 {
  -ms-flex-preferred-size: 16.667%;
  -webkit-flex-basis: 16.667%;
  flex-basis: 16.667%;
  max-width: 16.667%; }

.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  -webkit-flex-basis: 25%;
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  -ms-flex-preferred-size: 33.333%;
  -webkit-flex-basis: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%; }

.col-xs-5 {
  -ms-flex-preferred-size: 41.667%;
  -webkit-flex-basis: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%; }

.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  -ms-flex-preferred-size: 58.333%;
  -webkit-flex-basis: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%; }

.col-xs-8 {
  -ms-flex-preferred-size: 66.667%;
  -webkit-flex-basis: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%; }

.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  -webkit-flex-basis: 75%;
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  -ms-flex-preferred-size: 83.333%;
  -webkit-flex-basis: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%; }

.col-xs-11 {
  -ms-flex-preferred-size: 91.667%;
  -webkit-flex-basis: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%; }

.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-1 {
  margin-left: 8.333%; }

.col-xs-offset-2 {
  margin-left: 16.667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.333%; }

.col-xs-offset-5 {
  margin-left: 41.667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.333%; }

.col-xs-offset-8 {
  margin-left: 66.667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.333%; }

.col-xs-offset-11 {
  margin-left: 91.667%; }

.start-xs {
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: start; }

.center-xs {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center; }

.end-xs {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  text-align: end; }

.top-xs {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start; }

.middle-xs {
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center; }

.bottom-xs {
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end; }

.around-xs {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.between-xs {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between; }

.first-xs {
  -webkit-order: -1;
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  order: -1; }

.last-xs {
  -webkit-order: 1;
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1; }

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem; }

  .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    box-sizing: border-box;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }

  .col-sm {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    flex-basis: 0;
    max-width: 100%; }

  .col-sm-1 {
    -ms-flex-preferred-size: 8.333%;
    -webkit-flex-basis: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }

  .col-sm-2 {
    -ms-flex-preferred-size: 16.667%;
    -webkit-flex-basis: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }

  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
    max-width: 25%; }

  .col-sm-4 {
    -ms-flex-preferred-size: 33.333%;
    -webkit-flex-basis: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }

  .col-sm-5 {
    -ms-flex-preferred-size: 41.667%;
    -webkit-flex-basis: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }

  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%; }

  .col-sm-7 {
    -ms-flex-preferred-size: 58.333%;
    -webkit-flex-basis: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }

  .col-sm-8 {
    -ms-flex-preferred-size: 66.667%;
    -webkit-flex-basis: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }

  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    -webkit-flex-basis: 75%;
    flex-basis: 75%;
    max-width: 75%; }

  .col-sm-10 {
    -ms-flex-preferred-size: 83.333%;
    -webkit-flex-basis: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }

  .col-sm-11 {
    -ms-flex-preferred-size: 91.667%;
    -webkit-flex-basis: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }

  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%; }

  .col-sm-offset-1 {
    margin-left: 8.333%; }

  .col-sm-offset-2 {
    margin-left: 16.667%; }

  .col-sm-offset-3 {
    margin-left: 25%; }

  .col-sm-offset-4 {
    margin-left: 33.333%; }

  .col-sm-offset-5 {
    margin-left: 41.667%; }

  .col-sm-offset-6 {
    margin-left: 50%; }

  .col-sm-offset-7 {
    margin-left: 58.333%; }

  .col-sm-offset-8 {
    margin-left: 66.667%; }

  .col-sm-offset-9 {
    margin-left: 75%; }

  .col-sm-offset-10 {
    margin-left: 83.333%; }

  .col-sm-offset-11 {
    margin-left: 91.667%; }

  .start-sm {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start; }

  .center-sm {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center; }

  .end-sm {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end; }

  .top-sm {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start; }

  .middle-sm {
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center; }

  .bottom-sm {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end; }

  .around-sm {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around; }

  .between-sm {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between; }

  .first-sm {
    -webkit-order: -1;
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1; }

  .last-sm {
    -webkit-order: 1;
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1; } }
@media only screen and (min-width: 62em) {
  .container {
    width: 61rem; }

  .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
    box-sizing: border-box;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }

  .col-md {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    flex-basis: 0;
    max-width: 100%; }

  .col-md-1 {
    -ms-flex-preferred-size: 8.333%;
    -webkit-flex-basis: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }

  .col-md-2 {
    -ms-flex-preferred-size: 16.667%;
    -webkit-flex-basis: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }

  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
    max-width: 25%; }

  .col-md-4 {
    -ms-flex-preferred-size: 33.333%;
    -webkit-flex-basis: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }

  .col-md-5 {
    -ms-flex-preferred-size: 41.667%;
    -webkit-flex-basis: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }

  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%; }

  .col-md-7 {
    -ms-flex-preferred-size: 58.333%;
    -webkit-flex-basis: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }

  .col-md-8 {
    -ms-flex-preferred-size: 66.667%;
    -webkit-flex-basis: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }

  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    -webkit-flex-basis: 75%;
    flex-basis: 75%;
    max-width: 75%; }

  .col-md-10 {
    -ms-flex-preferred-size: 83.333%;
    -webkit-flex-basis: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }

  .col-md-11 {
    -ms-flex-preferred-size: 91.667%;
    -webkit-flex-basis: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }

  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%; }

  .col-md-offset-1 {
    margin-left: 8.333%; }

  .col-md-offset-2 {
    margin-left: 16.667%; }

  .col-md-offset-3 {
    margin-left: 25%; }

  .col-md-offset-4 {
    margin-left: 33.333%; }

  .col-md-offset-5 {
    margin-left: 41.667%; }

  .col-md-offset-6 {
    margin-left: 50%; }

  .col-md-offset-7 {
    margin-left: 58.333%; }

  .col-md-offset-8 {
    margin-left: 66.667%; }

  .col-md-offset-9 {
    margin-left: 75%; }

  .col-md-offset-10 {
    margin-left: 83.333%; }

  .col-md-offset-11 {
    margin-left: 91.667%; }

  .start-md {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start; }

  .center-md {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center; }

  .end-md {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end; }

  .top-md {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start; }

  .middle-md {
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center; }

  .bottom-md {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end; }

  .around-md {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around; }

  .between-md {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between; }

  .first-md {
    -webkit-order: -1;
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1; }

  .last-md {
    -webkit-order: 1;
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1; } }
@media only screen and (min-width: 75em) {
  .container {
    width: 71rem; }

  .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9 {
    box-sizing: border-box;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }

  .col-lg {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    flex-basis: 0;
    max-width: 100%; }

  .col-lg-1 {
    -ms-flex-preferred-size: 8.333%;
    -webkit-flex-basis: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }

  .col-lg-2 {
    -ms-flex-preferred-size: 16.667%;
    -webkit-flex-basis: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }

  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
    max-width: 25%; }

  .col-lg-4 {
    -ms-flex-preferred-size: 33.333%;
    -webkit-flex-basis: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }

  .col-lg-5 {
    -ms-flex-preferred-size: 41.667%;
    -webkit-flex-basis: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }

  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    max-width: 50%; }

  .col-lg-7 {
    -ms-flex-preferred-size: 58.333%;
    -webkit-flex-basis: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }

  .col-lg-8 {
    -ms-flex-preferred-size: 66.667%;
    -webkit-flex-basis: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }

  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    -webkit-flex-basis: 75%;
    flex-basis: 75%;
    max-width: 75%; }

  .col-lg-10 {
    -ms-flex-preferred-size: 83.333%;
    -webkit-flex-basis: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }

  .col-lg-11 {
    -ms-flex-preferred-size: 91.667%;
    -webkit-flex-basis: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }

  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    max-width: 100%; }

  .col-lg-offset-1 {
    margin-left: 8.333%; }

  .col-lg-offset-2 {
    margin-left: 16.667%; }

  .col-lg-offset-3 {
    margin-left: 25%; }

  .col-lg-offset-4 {
    margin-left: 33.333%; }

  .col-lg-offset-5 {
    margin-left: 41.667%; }

  .col-lg-offset-6 {
    margin-left: 50%; }

  .col-lg-offset-7 {
    margin-left: 58.333%; }

  .col-lg-offset-8 {
    margin-left: 66.667%; }

  .col-lg-offset-9 {
    margin-left: 75%; }

  .col-lg-offset-10 {
    margin-left: 83.333%; }

  .col-lg-offset-11 {
    margin-left: 91.667%; }

  .start-lg {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start; }

  .center-lg {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center; }

  .end-lg {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end; }

  .top-lg {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start; }

  .middle-lg {
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center; }

  .bottom-lg {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end; }

  .around-lg {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around; }

  .between-lg {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between; }

  .first-lg {
    -webkit-order: -1;
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1; }

  .last-lg {
    -webkit-order: 1;
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1; } }
.index .mainvisual {
  min-width: 1200px;
  height: 400px;
  background: #E95068;
  background-image: linear-gradient(-90deg, #E36E54, #E3546E); }
  .index .mainvisual__loading {
    width: 100%;
    height: 400px;
    position: absolute;
    display: table;
    top: 0;
    left: 0;
    z-index: 10;
    background: #E95068;
    background-image: linear-gradient(-90deg, #E36E54, #E3546E); }
    .index .mainvisual__loading img {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      margin-top: -20px;
      margin-left: -48px; }
  .index .mainvisual-inner {
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: absolute;
    z-index: 0;
    top: 0; }
  .index .mainvisual-bg {
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 0;
    top: 0; }
  .index .mainvisual__scroll {
    text-align: center;
    position: absolute;
    display: inline-block;
    background: #FFF;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    bottom: -30px;
    left: 50%;
    margin-left: -30px;
    box-shadow: 0 6px 35px 3px rgba(57, 57, 57, 0.2);
    z-index: 10; }
    .index .mainvisual__scroll img {
      position: relative;
      top: 20px; }
  .index .mainvisual__slides {
    margin-top: 100px;
    width: 100%;
    height: 200px;
    color: #FFF;
    text-align: center;
    display: inline-block;
    position: relative;
    z-index: 8;
    overflow: hidden; }
    .index .mainvisual__slides-next {
      position: absolute;
      right: 150px;
      top: 50%;
      z-index: 9;
      cursor: pointer; }
    .index .mainvisual__slides-prev {
      position: absolute;
      left: 150px;
      top: 50%;
      z-index: 9;
      cursor: pointer; }
    .index .mainvisual__slides-left {
      position: absolute; }
    .index .mainvisual__slides .slide__title {
      font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
      font-size: 4.6rem; }
    .index .mainvisual__slides .slide__caption {
      font-family: "baskerville-urw", "游明朝体", "Yu Mincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
      font-size: 1.8rem;
      font-style: italic;
      display: inline-block;
      position: relative; }
      .index .mainvisual__slides .slide__caption:before, .index .mainvisual__slides .slide__caption:after {
        width: 300px;
        height: 1px;
        display: block;
        content: "";
        background: rgba(255, 255, 255, 0.2);
        position: absolute; }
      .index .mainvisual__slides .slide__caption:before {
        top: 50%;
        right: -320px; }
      .index .mainvisual__slides .slide__caption:after {
        top: 50%;
        left: -320px; }
.index .block {
  text-align: center;
  margin-bottom: 60px; }
  .index .block__title {
    font-family: "baskerville-urw", "游明朝体", "Yu Mincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
    font-size: 4.6rem;
    color: #002359;
    margin-bottom: 20px;
    position: relative; }
    .index .block__title:after {
      display: block;
      content: "";
      background: url("../images/common/icon-title.svg") no-repeat center top;
      background-size: contain;
      width: 20px;
      height: 15px;
      margin: 10px auto 0 auto; }
  .index .block__lead {
    font-size: 1.6rem;
    margin-bottom: 40px; }
  .index .block-service {
    width: 990px;
    margin: 0 auto 60px; }
    .index .block-service .block-service__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .index .block-service .block-service__row .block-row__col {
        width: 480px;
        margin-bottom: 30px; }
      .index .block-service .block-service__row .card__img {
        width: 100%;
        height: auto; }
      .index .block-service .block-service__row .card {
        position: relative;
        background: #FFF;
        text-align: left;
        box-shadow: 0 4px 20px 0 rgba(57, 57, 57, 0.1); }
      .index .block-service .block-service__row .card__text {
        padding: 20px 30px; }
        .index .block-service .block-service__row .card__text dl {
          margin-bottom: 20px;
          border-bottom: 1px solid rgba(57, 57, 57, 0.1);
          padding-bottom: 10px; }
          .index .block-service .block-service__row .card__text dl dt {
            font-size: 1.8rem;
            color: #002359;
            font-weight: bold;
            margin-bottom: 2px; }
          .index .block-service .block-service__row .card__text dl dd {
            font-size: 1.2rem;
            color: #393939; }
      .index .block-service .block-service__row .card__navi {
        padding: 0 0 30px;
        text-align: center; }
        .index .block-service .block-service__row .card__navi a {
          width: 50%;
          transition: 0.2s ease-in-out; }
          .index .block-service .block-service__row .card__navi a:hover {
            opacity: 0.6;
            width: 80%; }
  .index .block-company {
    width: 100%;
    padding: 60px 0 100px 0;
    background: url("../images/index/company-bg.png") no-repeat center top;
    background-size: cover;
    position: relative;
    overflow: hidden; }
    .index .block-company:after {
      content: "";
      background-image: url("../images/index/company-mask.svg");
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: 110%;
      position: absolute;
      width: 100%;
      height: 100px;
      bottom: 0;
      left: 0;
      display: block; }
    .index .block-company .block__title,
    .index .block-company .block__lead {
      color: #FFF;
      position: relative;
      z-index: 2; }
    .index .block-company .block__lead {
      font-weight: bold; }
    .index .block-company .btn-company {
      background: #FFF;
      font-weight: bold;
      width: 290px;
      padding: 10px 0;
      border-radius: 30px; }
    .index .block-company .block-company__btn {
      position: relative;
      z-index: 2; }
    .index .block-company__earth {
      width: 1000px;
      left: 50%;
      top: 50px;
      margin-left: -500px;
      position: absolute;
      animation-name: rotateLoop;
      animation-duration: 200s;
      animation-direction: normal;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      z-index: 0; }
      .index .block-company__earth img {
        opacity: 0.4; }
  .index .block-information {
    width: 990px;
    margin: 0 auto 60px auto; }
    .index .block-information a {
      display: block;
      transform: scale(1);
      transition: 0.2s ease-in-out; }
      .index .block-information a:hover {
        transform: scale(1.05); }
    .index .block-information a:nth-child(2n) dl {
      background: rgba(243, 242, 242, 0.2); }
    .index .block-information dl {
      background: #FFF;
      text-align: left;
      padding: 15px 20px;
      position: relative; }
      .index .block-information dl:after {
        content: "navigate_next";
        position: absolute;
        color: #F3F2F2;
        right: 10px; }
    .index .block-information dl dt {
      display: inline-block;
      margin-right: 20px; }
    .index .block-information dl dd {
      display: inline-block; }
  .index .block-clients {
    width: 100%;
    background: #FFF;
    margin: 0;
    padding: 40px 0; }
    .index .block-clients__list {
      width: 990px;
      margin: 0 auto;
      padding-bottom: 30px;
      letter-spacing: -.40em; }
      .index .block-clients__list li {
        width: 220px;
        margin: 0 10px 20px 10px;
        height: 70px;
        letter-spacing: normal;
        background: #EEE;
        display: inline-block; }

@keyframes rotateLoop {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(-360deg); } }
.service .mainvisual {
  height: 250px;
  background: url("../images/common/mainvisual-under.png") no-repeat center top;
  background-size: cover;
  margin-bottom: 60px; }
  .service .mainvisual__title h1 {
    margin-bottom: 10px; }
    .service .mainvisual__title h1:after {
      display: block;
      content: "";
      background: url("../images/common/icon-title-white.svg") no-repeat center top;
      background-size: contain;
      width: 26px;
      height: 15px;
      margin: 0 auto 0 auto; }
.service .block {
  text-align: center;
  margin-bottom: 60px; }
  .service .block__title {
    font-family: "baskerville-urw", "游明朝体", "Yu Mincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
    font-size: 4.6rem;
    margin-bottom: 20px;
    position: relative; }
  .service .block-lead {
    font-size: 1.6rem;
    margin-bottom: 60px;
    line-height: 2.1; }
  .service .block-service {
    width: 990px;
    background: #FFF;
    margin: 0 auto 60px; }
    .service .block-service__head {
      padding: 20px 60px;
      display: flex;
      justify-content: space-between;
      position: relative; }
      .service .block-service__head::after {
        content: "";
        width: 870px;
        height: 10px;
        position: absolute;
        bottom: -4px;
        background: #111; }
      .service .block-service__head__hgroup {
        text-align: left; }
        .service .block-service__head__hgroup h2 {
          font-size: 32px;
          font-weight: bold; }
      .service .block-service__head__navi {
        display: flex;
        justify-content: center;
        align-items: center; }
        .service .block-service__head__navi a {
          width: 380px;
          font-size: 14px; }
    .service .block-service__text {
      padding: 40px 60px;
      width: 100%;
      text-align: left; }
      .service .block-service__text dl {
        display: flex;
        width: 100%;
        margin-bottom: 40px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(57, 57, 57, 0.3); }
        .service .block-service__text dl:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0; }
        .service .block-service__text dl dt {
          width: 30%;
          font-size: 18px;
          font-weight: bold;
          color: #002359; }
          .service .block-service__text dl dt .icon {
            display: inline-block;
            margin-right: 10px; }
        .service .block-service__text dl dd {
          width: 70%; }
          .service .block-service__text dl dd li {
            margin-bottom: 10px;
            position: relative; }
            .service .block-service__text dl dd li::before {
              content: "・";
              position: absolute;
              left: -14px; }
      .service .block-service__text h3 {
        font-size: 1.8rem;
        color: #002359;
        font-weight: bold;
        margin-bottom: 10px; }
      .service .block-service__text p {
        margin-bottom: 30px;
        line-height: 2.1; }
    .service .block-service__sign {
      padding: 0px 60px 40px;
      width: 100%;
      font-size: 11px;
      text-align: right; }
      .service .block-service__sign .logo {
        display: inline-block;
        vertical-align: middle;
        max-width: 100px;
        padding: 0 4px; }
.service #service1 .block-service__head::after {
  background: #AD0000; }
.service #service1 .block-service__head__hgroup {
  color: #AD0000; }
.service #service2 .block-service__head::after {
  background: #385ea6; }
.service #service2 .block-service__head__hgroup {
  color: #385ea6; }
.service #service3 .block-service__head::after {
  background: #06924c; }
.service #service3 .block-service__head__hgroup {
  color: #06924c; }
.service #service4 .block-service__head::after {
  background: #545454; }
.service #service4 .block-service__head__hgroup {
  color: #545454; }

.company .mainvisual {
  height: 250px;
  background: url("../images/common/mainvisual-under.png") no-repeat center top;
  background-size: cover;
  margin-bottom: 60px; }
  .company .mainvisual__title h1 {
    margin-bottom: 10px; }
    .company .mainvisual__title h1:after {
      display: block;
      content: "";
      background: url("../images/common/icon-title-white.svg") no-repeat center top;
      background-size: contain;
      width: 26px;
      height: 15px;
      margin: 0 auto 0 auto; }
.company .block h3 {
  font-size: 2.6rem;
  font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
  color: #002359;
  margin-bottom: 30px;
  position: relative; }
  .company .block h3:after {
    display: block;
    content: "";
    background: url("../images/common/icon-title.svg") no-repeat center top;
    background-size: contain;
    width: 20px;
    height: 15px;
    margin: 10px auto; }
.company .block-message {
  width: 990px;
  text-align: center;
  margin: 0 auto 60px auto;
  color: #002359;
  padding: 60px 0;
  background: url("../images/company/message-bg.png") no-repeat center center; }
  .company .block-message p {
    font-size: 2.2rem;
    font-family: "游明朝体", "Yu Mincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
    line-height: 2.4; }
.company .block-gene {
  width: 690px;
  margin: 0 auto 60px auto; }
  .company .block-gene h4 {
    text-align: center;
    color: #002359;
    font-size: 1.8rem;
    margin-bottom: 20px;
    position: relative; }
    .company .block-gene h4:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      width: 100%;
      height: 1px;
      background: #002359; }
    .company .block-gene h4 span {
      display: inline-block;
      background: #F3F2F2;
      position: relative;
      padding: 0 20px;
      z-index: 2; }
  .company .block-gene p,
  .company .block-gene ul {
    font-size: 1.2rem;
    line-height: 2; }
  .company .block-gene p {
    margin-bottom: 10px; }
  .company .block-gene ul {
    list-style: disc;
    list-style-position: inside; }
.company .block-info {
  padding: 30px 0;
  margin-bottom: 60px;
  background: #FFF;
  text-align: center; }
  .company .block-info__tables {
    width: 990px;
    margin: 0 auto; }
    .company .block-info__tables table {
      width: 46%;
      margin: 0 15px;
      display: inline-block;
      vertical-align: top; }
    .company .block-info__tables table th,
    .company .block-info__tables table td {
      border-top: 1px solid #E5E5E5;
      padding: 20px 10px;
      text-align: left; }
    .company .block-info__tables table th {
      width: 25%; }
    .company .block-info__tables table td {
      width: 75%; }
    .company .block-info__tables .xsmall {
      display: block; }
.company .block-history {
  text-align: center;
  width: 990px;
  margin: 0 auto 120px auto; }
  .company .block-history dl {
    background: #FFF;
    text-align: left;
    padding: 15px 20px;
    margin-bottom: 2px;
    position: relative; }
    .company .block-history dl:nth-child(2n) {
      background: rgba(243, 242, 242, 0.2); }
  .company .block-history dl dt {
    display: inline-block;
    margin-right: 20px;
    vertical-align: top; }
  .company .block-history dl dd {
    display: inline-block; }

.contact .form-lead {
  text-align: center;
  margin-bottom: 40px; }
.contact .form-error {
  color: #AD0000;
  list-style: disc;
  list-style-position: inside;
  margin-bottom: 30px; }
.contact .form-control {
  display: block;
  width: 100%;
  min-height: 34px;
  padding: 1rem .7rem;
  font-size: 1.3rem;
  line-height: 1.5;
  background: #F3F2F2;
  color: #55595C;
  background-image: none;
  border: 1px solid #FFF;
  border-radius: 0.25rem; }
.contact .row {
  align-items: center;
  justify-content: center; }
.contact .label-required {
  display: inline-block;
  font-weight: bold;
  margin: 4px;
  font-size: 1.2rem;
  padding: 0 4px;
  border-radius: 2px;
  color: #FFF;
  background: #AD0000; }
.contact .mainvisual {
  height: 250px;
  background: url("../images/common/mainvisual-under.png") no-repeat center top;
  background-size: cover;
  margin-bottom: 60px; }
  .contact .mainvisual__title h1 {
    margin-bottom: 10px; }
    .contact .mainvisual__title h1:after {
      display: block;
      content: "";
      background: url("../images/common/icon-title-white.svg") no-repeat center top;
      background-size: contain;
      width: 26px;
      height: 15px;
      margin: 0 auto 0 auto; }
.contact .block-form {
  width: 890px;
  margin: 0 auto 120px; }
.contact .form-group {
  padding: 15px 20px;
  margin-bottom: 2px;
  background: #FFF; }
.contact .form-btn {
  margin-top: 30px;
  text-align: center; }
.contact textarea {
  height: 200px; }


