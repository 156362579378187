// commons.scss

.hidden{
	display: none !important;
}

.smt{
	display: none;
}

@include mediaquery-smt {
	.smt{
		display: block;
	}
}

@mixin baseWidth {
    width: $width-pc;
    margin: 0 auto;
    @include mediaquery-smt {
        width: 100%;
        margin: auto;
    }
}
@mixin titleUnder {
    h2{
        font-size: 26px;
        font-family: $font-family-jp-light;
        font-weight: 400;
        color: $txt-color-blue;
        line-height: 1.2;
    }
    @include mediaquery-smt {
        h2{
            font-size: 26px;
        }
    }
}
