.company {
	@include mainvisual-under;

	.block {

		h3 {
			font-size: $font-size-h2;
			font-family: $font-family-jp-serif;
			color: $color-blue;
			margin-bottom: 30px;
			position: relative;

			&:after {
				display: block;
				content: "";
				background: url("../images/common/icon-title.svg") no-repeat center top;
				background-size: contain;
				width: 20px;
				height: 15px;
				margin: 10px auto;
			}
		}

		&-message {
			width: $width-pc;
			text-align: center;
			margin: 0 auto 60px auto;
			color: $color-blue;
			padding: 60px 0;
			background: url("../images/company/message-bg.png") no-repeat center center;

			p {
				font-size: 2.2rem;
				font-family: $font-family-jp-serif;
				line-height: 2.4;
			}
		}

		&-gene {
			width: $width-pc - 300px;
			margin: 0 auto 60px auto;

			h4 {
				text-align: center;
				color: $color-blue;
				font-size: $font-size-h3;
				margin-bottom: 20px;
				position: relative;

				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 50%;
					width: 100%;
					height: 1px;
					background: $color-blue;
				}

				span {
					display: inline-block;
					background: $color-lightgray;
					position: relative;
					padding: 0 20px;
					z-index: 2;
				}
			}

			p,
			ul {
				font-size: $font-size-h6;
				line-height: 2;
			}

			p {
				margin-bottom: 10px;
			}

			ul {
				list-style: disc;
				list-style-position: inside;
			}
		}

		&-info {
			padding: 30px 0;
			margin-bottom: 60px;
			background: $color-white;
			text-align: center;

			&__tables {
				width: $width-pc;
				margin: 0 auto;

				table {
					width: 46%;
					margin: 0 15px;
					display: inline-block;
					vertical-align: top;
				}

				table th,
				table td {
					border-top: 1px solid #E5E5E5;
					padding: 20px 10px;
					text-align: left;
				}

				table th {
					width: 25%;
				}

				table td {
					width: 75%;
				}

				.xsmall {
					display: block;
				}
			}
		}

		&-history {
			text-align: center;
			width: $width-pc;
			margin: 0 auto 120px auto;

			dl {
				background: $color-white;
				text-align: left;
				padding: 15px 20px;
				margin-bottom: 2px;
				position: relative;

				&:nth-child(2n) {
					background: rgba($color-lightgray, 0.2);
				}
			}

			dl dt {
				display: inline-block;
				margin-right: 20px;
				vertical-align: top;
			}

			dl dd {
				display: inline-block;
			}
		}
	}
}
