
.service {
	@include mainvisual-under;

	.block {
		text-align: center;
		margin-bottom: 60px;

		&__title {
			font-family: $font-family-en-serif;
			font-size: $font-size-h1;
			margin-bottom: 20px;
			position: relative;
		}

		&-lead {
			font-size: $font-size-h4;
			margin-bottom: 60px;
			line-height: 2.1;
		}

		&-service {
			width: $width-pc;
			background: $color-white;
			margin: 0 auto 60px;

			&__img {
			}

			&__head {
				padding: 20px 60px;
				display: flex;
				justify-content: space-between;
				position: relative;

				&::after {
					content: "";
					width: 870px;
					height: 10px;
					position: absolute;
					bottom: -4px;
					background: $color-black;
				}

				&__hgroup {
					text-align: left;

					h2 {
						font-size: 32px;
						font-weight: bold;
					}
				}

				&__navi {
					display: flex;
					justify-content: center;
					align-items: center;
					a {
						width: 380px;
						font-size: 14px;
					}
				}
			}

			&__text {
				padding: 40px 60px;
				width: 100%;
				text-align: left;

				dl {
					display: flex;
					width: 100%;
					margin-bottom: 40px;
					padding-bottom: 20px;
					border-bottom: 1px solid rgba($color-gray, 0.3);

					&:last-child {
						border-bottom: none;
						padding-bottom: 0;
						margin-bottom: 0;
					}

					dt {
						width: 30%;
						font-size: 18px;
						font-weight: bold;
						color: $color-blue;

						.icon {
							display: inline-block;
							margin-right: 10px;
						}
					}

					dd {
						width: 70%;

						li {
							margin-bottom: 10px;
							position: relative;

							&::before {
								content: "・";
								position: absolute;
								left: -14px;
							}
						}
					}
				}

				h3 {
					font-size: $font-size-h3;
					color: $color-blue;
					font-weight: bold;
					margin-bottom: 10px;
			   }

				p {
					margin-bottom: 30px;
					line-height: 2.1;
				}
			}

			&__sign {
				padding: 0px 60px 40px;
				width: 100%;
				font-size: 11px;
				text-align: right;
				.logo {
					display: inline-block;
					vertical-align: middle;
					max-width: 100px;
					padding: 0 4px;
				}
			}
		}
	}

	#service1 {
		.block-service__head {
			&::after {
				background: $color-brand;
			}
		}
		.block-service__head__hgroup {
			color: $color-brand;
		}
	}

	#service2 {
		.block-service__head {
			&::after {
				background: #385ea6;
			}
		}
		.block-service__head__hgroup {
			color: #385ea6;
		}
	}

	#service3 {
		.block-service__head {
			&::after {
				background: #06924c;
			}
		}
		.block-service__head__hgroup {
			color: #06924c;
		}
	}

	#service4 {
		.block-service__head {
			&::after {
				background: #545454;
			}
		}
		.block-service__head__hgroup {
			color: #545454;
		}
	}
}
