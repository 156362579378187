// base.scss

html,
body {
	width: 100%;
	height: 100%;
}

html {
	visibility: hidden;
}

.wf-active {
	visibility: visible;
}

html {
	font-family: $font-family-sans-serif;
	font-size: 62.5%;
	text-size-adjust: 100%;
	color: $color-black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	line-height: $line-height-base;
	font-size: $font-size-base;
	background: $color-lightgray;
}

* {
	box-sizing: border-box;
}

img {
	max-width: 100%;
	vertical-align: top;
}

a {
	color: $color-blue;
	text-decoration: none;

	&:hover {
		color: rgba($color-blue, 0.7);
	}
}

.small {
	font-size: $font-size-sm;
}

.xsmall {
	font-size: $font-size-xs;
}

.noscroll {
	position: fixed;
	overflow-x: scroll;
	overflow-y: scroll;
}

.gwrapper {
	min-width: 1200px;
}

.gfooter {
	padding: 0 0 60px 0;
	width: 100%;
	text-align: left;
	color: $color-white;
	background: #AD0000;

	&__content {
		padding: 30px 0 30px 0;
		background: $color-gray;

		&-inner {
			@include clearfix;
			width: $width-pc;
			margin: 0 auto;
		}
	}

	.content {
		&__address {
			width: 480px;
			float: left;
			text-align: left;

			&-siteid {
				width: 180px;
				vertical-align: top;
				display: inline-block;
				padding-right: 10px;
			}

			&-text {
				width: 280px;
				display: inline-block;
				font-size: $font-size-sm;
			}
		}

		&__share {
			width: 200px;
			float: left;
			height: 80px;
			padding-left: 20px;
			border-left: 1px solid rgba($color-white, 0.2);
		}

		&__contact {
			width: 300px;
			float: right;
			text-align: center;

			&-btn {
				margin-bottom: 5px;
			}

			&-btn i {
				margin-right: 4px;
			}

			&-btn .btn-footer {
				width: 290px;
				padding: 10px 0;
				border-radius: 30px;
				font-size: 1.6rem;
				background: $color-brand;
				color: $color-white;
				font-weight: bold;
			}

			&-link a {
				font-size: $font-size-sm;
				color: $color-white;
			}

		}
	}

	address {
		padding-top: 30px;
		text-align: center;
	}
}

.gheader {
	@include clearfix;
	border-bottom: 1px solid rgba($color-white, 0.2);
	position: relative;
	z-index: 10;

	&-nav {
		display: inline-block;
		float: right;
		border-left: 1px solid rgba($color-white, 0.2);
		padding-left: 30px;
		padding-right: 20px;
		font-weight: bold;

		li {
			display: inline-block;
			vertical-align: middle;
			padding: 15px 15px;
			position: relative;

		}

		.active {
			&:after {
				content: "";
				display: block;
				position: absolute;
				bottom: 10px;
				left: 0;
				width: 100%;
				height: 1px;
				background: $color-white;
			}
		}

		.active.contact:after {
			display: none;
		}

		li a {
			color: $color-white;
		}

		i {
			margin-right: 4px;
		}

		.btn-contact {
			width: 200px;
			display: inline-block;
			text-align: center;
			padding: 9px 0;
			height: 40px;
			border-radius: 30px;
			background: $color-brand;
			color: $color-white;
			font-weight: bold;
		}
	}
}

.mainvisual {
	width: 100%;
	background: #666;
	position: relative;
	margin-bottom: 40px;

	&__siteid {
		position: absolute;
		width: 120px;
		left: 20px;
		top: 10px;
	}

	&__title {
		padding: 20px 0 30px 0;
		text-align: center;
		color: $color-white;

		h1 {
			font-family: $font-family-en-serif;
			font-size: $font-size-h1;
		}

		p {
			font-size: $font-size-h4;
		}
	}
}

@mixin mainvisual-under {
	.mainvisual {
		height: 250px;
		background: url("../images/common/mainvisual-under.png") no-repeat center top;
		background-size: cover;
		margin-bottom: 60px;

		&__title h1 {
			margin-bottom: 10px;

			&:after {
				display: block;
				content: "";
				background: url("../images/common/icon-title-white.svg") no-repeat center top;
				background-size: contain;
				width: 26px;
				height: 15px;
				margin: 0 auto 0 auto;
			}
		}
	}
}
