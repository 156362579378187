
@mixin formset {
	.form-lead {
		text-align: center;
		margin-bottom: 40px;
	}

	.form-error {
		color: $color-brand;
		list-style: disc;
		list-style-position: inside;
		margin-bottom: 30px;
	}

	.form-control {
		display: block;
		width: 100%;
		min-height: 34px;
		padding: 1rem .7rem;
		font-size: 1.3rem;
		line-height: 1.5;
		background: $color-lightgray;
		color: #55595C;
		background-image: none;
		border: 1px solid $color-white;
		border-radius: 0.25rem;
	}

	.row {
		align-items: center;
		justify-content: center;
	}

	.label {
		&-required {
			display: inline-block;
			font-weight: bold;
			margin: 4px;
			font-size: 1.2rem;
			padding: 0 4px;
			border-radius: $border-radius-small;
			color: $color-white;
			background: $color-brand;
		}
	}

}




.contact {
	@include formset;
	@include mainvisual-under;

	.block-form {
		width: $width-pc - 100;
		margin: 0 auto 120px;
	}

	.form-group {
		padding: 15px 20px;
		margin-bottom: 2px;
		background: $color-white;
	}

	.form-btn {
		margin-top: 30px;
		text-align: center;
	}

	textarea {
		height: 200px;
	}
}
