@charset "utf-8";

//basic color
$color-brand:	     	  #AD0000 !default;
$color-blue:			  #002359 !default;
$color-black:			  #111 !default;
$color-gray:			  #393939 !default;
$color-lightgray:         #F3F2F2 !default;
$color-white:			  #FFF !default;

//text color
$link-color:              $color-brand !default;
$link-decoration:         none !default;
$link-hover-color:        darken($link-color, 15%) !default;
$link-hover-decoration:   underline !default;

//button color
$btn-color-primary:	      $color-blue;
$btn-color-secondary:	  $color-brand;

// font
$font-family-sans-serif:    "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Lucida Grande", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif !default;
$font-family-serif:	        "游明朝体", "Yu Mincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif !default;
$font-family-jp-serif:	    $font-family-serif;
$font-family-en-serif:	    "baskerville-urw", $font-family-serif;


$font-size-root:        62.5% !default;

// font size heading
$font-size-base:        1.4rem !default;
$font-size-lg:          1.8rem !default;
$font-size-sm:          1.2rem !default;
$font-size-xs:          1rem !default;

$font-size-h1:          4.6rem !default;
$font-size-h2:          2.6rem !default;
$font-size-h3:          1.8rem !default;
$font-size-h4:          1.6rem !default;
$font-size-h5:          1.4rem !default;
$font-size-h6:          1.2rem !default;

// line height
$line-height-small:	    1.4 !default; // 20/14
$line-height-base:	    1.6 !default; // 20/14
$line-height-large:	    2.1 !default; // ~20px


//radius
$border-radius-small:	 2px;
$border-radius-base:	 6px;
$border-radius-large:	 40px;


// device width
$width-pc: 990px;
$width-smt: 764px;


// ease in
$ease-in-quad:	  cubic-bezier(0.55, 0.085, 0.680, 0.530);
$ease-in-cubic:	 cubic-bezier(0.550, 0.055, 0.675, 0.190);
$ease-in-quart:	 cubic-bezier(0.895, 0.030, 0.685, 0.220);
$ease-in-quint:	 cubic-bezier(0.755, 0.050, 0.855, 0.060);
$ease-in-sine:	  cubic-bezier(0.470, 0, 0.745, 0.715);
$ease-in-expo:	  cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ease-in-circ:	  cubic-bezier(0.600, 0.040, 0.980, 0.335);
$ease-in-back:	  cubic-bezier(0.600, -0.280, 0.735, 0.045);
// ease out
$ease-out-quad:	 cubic-bezier(0.250, 0.460, 0.450, 0.940);
$ease-out-cubic:	cubic-bezier(0.215, 0.610, 0.355, 1);
$ease-out-quart:	cubic-bezier(0.165, 0.840, 0.440, 1);
$ease-out-quint:	cubic-bezier(0.230, 1, 0.320, 1);
$ease-out-sine:	 cubic-bezier(0.390, 0.575, 0.565, 1);
$ease-out-expo:	 cubic-bezier(0.190, 1, 0.220, 1);
$ease-out-circ:	 cubic-bezier(0.075, 0.820, 0.165, 1);
$ease-out-back:	 cubic-bezier(0.175, 0.885, 0.320, 1.275);
// ease in out
$ease-in-out-quad:  cubic-bezier(0.455, 0.030, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart: cubic-bezier(0.770, 0, 0.175, 1);
$ease-in-out-quint: cubic-bezier(0.860, 0, 0.070, 1);
$ease-in-out-sine:  cubic-bezier(0.445, 0.050, 0.550, 0.950);
$ease-in-out-expo:  cubic-bezier(1, 0, 0, 1);
$ease-in-out-circ:  cubic-bezier(0.785, 0.135, 0.150, 0.860);
$ease-in-out-back:  cubic-bezier(0.680, -0.550, 0.265, 1.550);


.material-icons {
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	font-size: 24px; /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	vertical-align: middle;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
	/* Support for IE. */
	font-feature-settings: "liga";
}
