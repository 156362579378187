
.index {
	.mainvisual {
		min-width: 1200px;
		height: 400px;
		background: #E95068;
		background-image: linear-gradient(-90deg, #E36E54, #E3546E);

		&__loading {
			width: 100%;
			height: 400px;
			position: absolute;
			display: table;
			top: 0;
			left: 0;
			z-index: 10;
			background: #E95068;
			background-image: linear-gradient(-90deg, #E36E54, #E3546E);

			img {
				position: absolute;
				display: block;
				left: 50%;
				top: 50%;
				margin-top: -20px;
				margin-left: -48px;
			}
		}

		&-inner {
			width: 100%;
			height: 400px;
			overflow: hidden;
			position: absolute;
			z-index: 0;
			top: 0;
		}

		&-bg {
			width: 100%;
			overflow: hidden;
			position: absolute;
			z-index: 0;
			top: 0;
		}

		&__scroll {
			text-align: center;
			position: absolute;
			display: inline-block;
			background: $color-white;
			width: 60px;
			height: 60px;
			border-radius: 30px;
			bottom: -30px;
			left: 50%;
			margin-left: -30px;
			box-shadow: 0 6px 35px 3px rgba($color-gray, 0.2);
			z-index: 10;

			img {
				position: relative;
				top: 20px;
			}
		}


		&__slides {
			margin-top: 100px;
			width: 100%;
			height: 200px;
			color: $color-white;
			text-align: center;
			display: inline-block;
			position: relative;
			z-index: 8;
			overflow: hidden;

			&-next {
				position: absolute;
				right: 150px;
				top: 50%;
				z-index: 9;
				cursor: pointer;
			}

			&-prev {
				position: absolute;
				left: 150px;
				top: 50%;
				z-index: 9;
				cursor: pointer;
			}

			&-left {
				position: absolute;
			}

			.slide__title {
				font-family: $font-family-jp-serif;
				font-size: $font-size-h1;
			}

			.slide__caption {
				font-family: $font-family-en-serif;
				font-size: $font-size-h3;
				font-style: italic;
				display: inline-block;
				position: relative;

				&:before,
				&:after {
					width: 300px;
					height: 1px;
					display: block;
					content: "";
					background: rgba($color-white, 0.2);
					position: absolute;
				}

				&:before {
					top: 50%;
					right: -320px;
				}

				&:after {
					top: 50%;
					left: -320px;
				}
			}
		}
	}


	.block {
		text-align: center;
		margin-bottom: 60px;

		&__title {
			font-family: $font-family-en-serif;
			font-size: $font-size-h1;
			color: $color-blue;
			margin-bottom: 20px;
			position: relative;

			&:after {
				display: block;
				content: "";
				background: url("../images/common/icon-title.svg") no-repeat center top;
				background-size: contain;
				width: 20px;
				height: 15px;
				margin: 10px auto 0 auto;
			}
		}

		&__lead {
			font-size: $font-size-h4;
			margin-bottom: 40px;
		}


		&-service {
			width: $width-pc;
			margin: 0 auto 60px;

			.block-service__row {
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.block-row__col {
					width: 480px;
					margin-bottom: 30px;
				}

				.card__img {
					width: 100%;
					height: auto;
				}

				.card {
					position: relative;
					background: $color-white;
					text-align: left;
					box-shadow: 0 4px 20px 0 rgba($color-gray, 0.1);
				}

				.card__text {
					padding: 20px 30px;

					dl {
						margin-bottom: 20px;
						border-bottom: 1px solid rgba($color-gray, 0.1);
						padding-bottom: 10px;

						dt {
							font-size: $font-size-h3;
							color: $color-blue;
							font-weight: bold;
							margin-bottom: 2px;
						}

						dd {
							font-size: $font-size-h6;
							color: $color-gray;
							
						}
					}
				}
				
				.card__navi {
					padding: 0 0 30px; 
					text-align: center;

					a {
						width: 50%;
						transition: 0.2s ease-in-out;

						&:hover {
							opacity: 0.6;
							width: 80%;
						}

					}

				}
			}
		}

		&-company {
			width: 100%;
			padding: 60px 0 100px 0;
			background: url("../images/index/company-bg.png") no-repeat center top;
			background-size: cover;
			position: relative;
			overflow: hidden;

			&:after {
				content: "";
				background-image: url("../images/index/company-mask.svg");
				background-repeat: no-repeat;
				background-position: bottom center;
				background-size: 110%;
				position: absolute;
				width: 100%;
				height: 100px;
				bottom: 0;
				left: 0;
				display: block;
			}

			.block__title,
			.block__lead {
				color: $color-white;
				position: relative;
				z-index: 2;
			}

			.block__lead {
				font-weight: bold;
			}

			.btn-company {
				background: $color-white;
				font-weight: bold;
				width: 290px;
				padding: 10px 0;
				border-radius: 30px;
			}

			.block-company__btn {
				position: relative;
				z-index: 2;
			}

			&__earth {
				width: 1000px;
				left: 50%;
				top: 50px;
				margin-left: -500px;
				position: absolute;
				animation-name: rotateLoop;
				animation-duration: 200s;
				animation-direction: normal;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
				z-index: 0;

				img {
					opacity: 0.4;
				}
			}

		}

		&-information {
			width: $width-pc;
			margin: 0 auto 60px auto;

			a {
				display: block;
				transform: scale(1);
				transition: 0.2s ease-in-out;

				&:hover {
					transform: scale(1.05);
				}
			}

			a:nth-child(2n) dl {
				background: rgba($color-lightgray, 0.2);
			}

			dl {
				background: $color-white;
				text-align: left;
				padding: 15px 20px;
				position: relative;

				&:after {
					@extend .material-icons;
					content: "navigate_next";
					position: absolute;
					color: $color-lightgray;
					right: 10px;
				}
			}

			dl dt {
				display: inline-block;
				margin-right: 20px;
			}

			dl dd {
				display: inline-block;
			}

		}

		&-clients {
			width: 100%;
			background: $color-white;
			margin: 0;
			padding: 40px 0;

			&__list {
				width: $width-pc;
				margin: 0 auto;
				padding-bottom: 30px;
				letter-spacing: -.40em;

				li {
					width: 220px;
					margin: 0 10px 20px 10px;
					height: 70px;
					letter-spacing: normal;
					background: #EEE;
					display: inline-block;
				}
			}
		}
	}
}


@keyframes rotateLoop {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(-360deg);
	}
}
