// buttons.scss

.btn {
	display: inline-block;
	padding: 5px 20px;
	margin-bottom: 0;
	font-size: $font-size-base;
	line-height: $line-height-base;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
	border-radius: $border-radius-base;

	&.btn-sm {
		font-size: $font-size-sm;
		padding: 10px 30px;
	}

	&.btn-lg {
		font-size: $font-size-lg;
		padding: 10px 40px;
	}

	&.btn-xlg {
		font-size: $font-size-lg;
		padding: 20px 100px;
		border-radius: $border-radius-large;
	}

	&-primary {
		color: $color-blue;
		font-weight: bold;
		border: 2px solid $btn-color-primary;
		text-decoration: none;
		border-radius: $border-radius-large;
		//box-shadow: darken($btn-color-primary, 5%) 0px 5px 0px 0px;
	}

	&-secondary {
		color: $color-white;
		border: $btn-color-secondary;
		background: $btn-color-secondary;
		font-weight: bold;
		text-decoration: none;
		border-radius: $border-radius-large;
		//box-shadow: darken($btn-color-secondary, 5%) 0px 5px 0px 0px;
	}


	[class^="icon-"] {
		margin-right: 5px;
	}

	&,
	&:active,
	&.active {
		&:focus,
		&.focus {
			opacity: 1;
		}
	}

	&:hover,
	&:focus,
	&.focus {
		text-decoration: none;
		opacity: 0.8;
	}

	&:active,
	&.active {
		outline: 0;
		background-image: none;
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	}

	&.disabled,
	&[disabled] {
		pointer-events: none;
		opacity: 0.5;
		box-shadow: none;
	}

}

@include mediaquery-smt {
	.btn {
		white-space: normal;
	}
}
