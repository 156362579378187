// mixin.scss

// clearfix
@mixin clearfix {
	zoom: 1;

	&:after {
		content: "";
		display: block;
		clear: both;
		visibility: hidden;
		height: 0.1px;
		font-size: 0.1em;
		line-height: 0;
	}
}

// animation
@mixin show-fade($time : 1s, $easing : $ease-out-cubic){
	//visibility: hidden;
	opacity: 0;
	@include transition-property(opacity);
	@include transition-duration($time);
	@include transition-timing-function($easing);

	&.show {
		//visibility: visible;
		opacity: 1;
	}

	&.hide {
		opacity: 0;
	}

	@include transform-style(preserve-3d);
	@include backface-visibility(hidden);
}

@mixin alpha-hover {
	@include transition-property(opacity);
	@include transform-style(preserve-3d);
	@include backface-visibility(hidden);
	@include transition-timing-function($ease-out-cubic);
	@include transition-duration(0.35s);
}

@mixin animation-common {
	@include transition-timing-function($ease-out-quart);
	@include transform-style(preserve-3d);
	@include backface-visibility(hidden);
}

@mixin css3($property, $value) {
	 @each $prefix in -webkit-, -moz-, -ms-, -o-, '' {
			#{$prefix}#{$property}: $value;
	 }
}

// animation keyframes
// @include keyframes(ANIMATIONNAME) {
//   0% { ATTRIBUTE: VALUE; ATTRIBUTE: VALUE; }
//   50% { ATTRIBUTE: VALUE; ATTRIBUTE: VALUE; }
//   100% { ATTRIBUTE: VALUE; ATTRIBUTE: VALUE; }
// }
@mixin keyframes($animationName) {
	@-webkit-keyframes #{$animationName} {
		@content;
	}
	@-moz-keyframes #{$animationName} {
		@content;
	}
	@-o-keyframes #{$animationName} {
		@content;
	}
	@keyframes #{$animationName} {
		@content;
	}
}

// media queries
@mixin mediaquery-pc {
  @media only screen and (min-width: $width-pc) {
	@content;
  }
}

@mixin mediaquery-smt {
	@media only screen and (max-width: $width-smt) {
		@content;
	}
}

// ie
@mixin only-ie8bellow(){
	html.ie8L & {
		@content;
	}
}

@mixin only-ie9bellow(){
	html.ie9L & {
		@content;
	}
}

@mixin only-noie(){
	html.noIE & {
		@content;
	}
}
